// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/entry.client.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/entry.client.tsx");
  import.meta.hot.lastModified = "1704193608846.4607";
}
// REMIX HMR END

import React, { useState } from 'react';
import { CacheProvider } from '@emotion/react';
import { RemixBrowser } from '@remix-run/react';
import { ClientStyleContext } from './context';
import createEmotionCache, { defaultCache } from './createEmotionCache';
import { hydrateRoot } from 'react-dom/client';
function ClientCacheProvider({
  children
}) {
  _s();
  const [cache, setCache] = useState(defaultCache);
  function reset() {
    setCache(createEmotionCache());
  }
  return <ClientStyleContext.Provider value={{
    reset
  }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>;
}
_s(ClientCacheProvider, "tcIOn6Sd77WzbBr/Pv1kFwXQ8Ls=");
_c = ClientCacheProvider;
hydrateRoot(document, <ClientCacheProvider>
    <RemixBrowser />
  </ClientCacheProvider>);
var _c;
$RefreshReg$(_c, "ClientCacheProvider");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;