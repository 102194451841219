// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/createEmotionCache.ts"
);
import.meta.hot.lastModified = "1704193608846.4607";
}
// REMIX HMR END

// createEmotionCache.ts
import createCache from '@emotion/cache'

export const defaultCache = createEmotionCache()

export default function createEmotionCache() {
  return createCache({ key: 'cha' })
}